<template>
  <div>
    <div style="padding: 10px">
      <el-button type="primary" size="mini" @click="importShow"
        >导入商品数据</el-button
      >
      <el-button type="warning" size="mini" @click="selDel">批量删除</el-button>
      <el-button type="warning" size="mini" @click="selDelShow">批量自定义删除</el-button>
      <!-- <el-button type="danger" size="mini">全部删除</el-button> -->
    </div>

    <el-divider content-position="left">{{ disDataInfo.name }}</el-divider>

    <div class="disListDiv">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
        height="600"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>

        <el-table-column prop="id" label="id" width="80" align="center">
        </el-table-column>

        <el-table-column label="图片" width="150" align="center">
          <template slot-scope="scope">
            <img
              height="60"
              :src="`http://hwimg.xmvogue.com/thumb/${scope.row.goods_code}.jpg?x-oss-process=style/440`"
              class="mr-3"
              alt="..."
            />
          </template>
        </el-table-column>

        <el-table-column
          prop="goods_code"
          label="产品编码"
          show-overflow-tooltip
          width="250"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="item_name" label="中文产品名" align="center">
          <template slot-scope="scope">{{ scope.row.item_name }}</template>
        </el-table-column>

        <el-table-column prop="base_price" label="配送价" align="center">
        </el-table-column>

        <el-table-column prop="sale_price" label="零售价" align="center">
        </el-table-column>

        <el-table-column prop="dis_price" label="折扣价" align="center">
        </el-table-column>

        <el-table-column prop="fixed_price" label="固定价" align="center">
          <template slot-scope="scope">
            {{ scope.row.fixed_price?scope.row.fixed_price:'' }}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="100px" v-if="disDataInfo.dis_type ===2 || disDataInfo.dis_type === 3">
          <template v-slot="{ row }">
            <el-button type="primary" plain @click="handleEdit(row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
<!-- 批量自定义删除商品 -->
    <el-drawer
          title="批量自定义删除商品"
          :visible.sync="delDrawer"
          :direction="direction"
          :before-close="handleClose"
        >
          <div style="width: 90%; margin: 0 auto">
            请输入属于当前折扣的商品，示例：
            <div style="font-size: 18px; color: #889aa4;font-weight: 600;margin-left: 40px;margin-top: 10px;">
              <span style="width: 100%;">6941196077497</span>
              <span style="width: 100%;">6941196071990</span>
              <span style="width: 100%;">6941700671135</span>
            </div>
            <el-input
              type="textarea"
              placeholder="输入产品SKU,每行一个SKU，如示例"
              rows="15"
              v-model="delGoodsCodeS"
            ></el-input>

            <el-button type="danger" style="margin: 10px" @click="batchDelGoodsSubmit"
              >删除</el-button
            >
          </div>
        </el-drawer>

    <!-- 导入产品 -->
    <el-drawer
      title="导入产品"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <div style="width: 90%; margin: 10px auto">
        <el-radio v-model="import_type" label="1" v-if="disDataInfo.dis_type ===2 || disDataInfo.dis_type === 3">{{import_type_title1}}</el-radio>
        <el-radio v-model="import_type" label="2" >{{import_type_title2}}</el-radio>
      </div>
      <div style="width: 90%; margin: 0 auto">
        <!--  -->
        <template>
          {{ goodsCodeSConfig.demoTitle }}示例: {{ goodsCodeSConfig.demoText }}
        <div style="font-size: 18px; color: #889aa4;font-weight: 600;margin-left: 40px;margin-top: 10px;">
          <pre>{{ goodsCodeSConfig.tip }}</pre>
        </div>
        <el-input
          type="textarea"
          :placeholder="goodsCodeSConfig.placeholder"
          rows="15"
          v-model="goodsCodeS"
        ></el-input>
        </template>
        <!--  -->
        <el-button type="success" style="margin: 10px" @click="implodeSubmit"
          >提交</el-button
        >
      </div>
    </el-drawer>
    <!-- 编辑商品 -->
    <el-drawer
      title="编辑产品"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleNowClose"
    >
    <div
        style="
          border: 1px solid #ccc;
          border-right: none;
          border-left: none;
          padding: 5px;
          width: 95%;
          margin: 0 auto;
        "
      >
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="图片">
            <img
              height="60"
              :src="`http://hwimg.xmvogue.com/thumb/${form.goods_code}.jpg?x-oss-process=style/440`"
              class="mr-3"
              alt="..."
            />
          </el-form-item>
          <el-form-item label="产品编码">
            <el-input v-model="form.goods_code" disabled></el-input>
          </el-form-item>
          <el-form-item label="中文产品名">
            <el-input v-model="form.item_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="配送价">
            <el-input v-model="form.base_price" disabled></el-input>
          </el-form-item>
          <el-form-item label="零售价">
            <el-input v-model="form.sale_price" disabled></el-input>
          </el-form-item>
          <!--  -->
          <template v-if="disDataInfo.dis_type==2">
            <el-form-item label="折扣价">
            <el-input v-model="form.dis_price"></el-input>
          </el-form-item>
          </template>
          <!--  -->
          <template v-else>
            <el-form-item label="折扣价" v-if="disDataInfo.dis_type!==3">
            <el-input v-model="form.dis_price" disabled></el-input>
          </el-form-item>
          </template>
          <!--  -->
          <el-form-item label="固定价格">
            <el-input v-model="form.fixed_price"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmitEdit">提交</el-button>
            <el-button @click="editDrawer = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      import_type:"",
      import_type_title1:"导入价格",
      import_type_title2:"不导入价格",
      goodsCodeS: "",
      goodsCodeSConfig:{
        demoTitle:this.import_type_title1,
        demoText:"",
        title:"",
        tip:"",
        placeholder:""
      },
      defaultText: "SKU + 空格（空格数量不限） + 价格(支持3种格式价格)",//默认提示
      defaultTip:"6941196077497 5.05\n6941196071990  1.8\n6941700671135 3", //默认提示
      defaultPlaceholder:"输入产品SKU、价格,每行一个SKU + 空格（空格数量不限） + 价格(支持3种格式价格)，如示例",//默认提示
      // 
      disType2Placeholder: "输入产品SKU、价格,每行一个SKU + 空格（空格数量不限）+ 折后价格(支持3种格式价格)  + 固定价格(支持3种格式价格)，如示例", //固定价，自定义折扣价类型的提示
      disType2Tip:"6941196077497 5.05 1.05\n6941196071990  1.8  1.2\n6941700671135 3 1" ,//固定价，自定义折扣价类型的提示
      disType2Text:"SKU + 空格（空格数量不限）+ 折扣价格(支持3种格式价格) + 固定价格(支持3种格式价格)" , //固定价，自定义折扣价类型的提示
      disDataInfo: {},
      disId: 0,
      drawer: false,
      // 批量自定义删除
      delDrawer:false,
      delGoodsCodeS:"",
      editDrawer:false,
      direction: "rtl",
      tableData: [],
      multipleSelection: [],
      form:{
        id:0,
        goods_code:"",
        item_name:"",
        base_price:0,
        sale_price:0,
        dis_price:0,
        fixed_price:0
      }
    };
  },
  created() {
    this.getDisId(); //获取到ID
    this.getData(); //获取到数据
    this.getDisInfo(); //获取详情
    //设置默认的提示
    setTimeout(() => {
      if (parseInt(this.disDataInfo.dis_type) === 2) {
        this.setDefaultTipText2()
      } else {
        this.setDefaultTipText()
      }
    }, 500);
  },
  watch:{
    'import_type'(val){
      switch (val) {
        case "2":
          this.goodsCodeSConfig.demoTitle=this.import_type_title2
          this.goodsCodeSConfig.demoText="SKU"
          this.goodsCodeSConfig.title= this.import_type_title2
          this.goodsCodeSConfig.tip = "6941196077497\n6941196071990\n6941700671135"
          this.goodsCodeSConfig.placeholder="输入产品SKU,每行一个SKU，如示例"
          break;
        default:
          if (parseInt(this.disDataInfo.dis_type) === 2) {
            this.setDefaultTipText2()
          } else {
            this.setDefaultTipText()
          }
      }
    }
  },
  methods: {
    // 设置默认提示
    setDefaultTipText(){
      this.goodsCodeSConfig.demoTitle = this.import_type_title1
      this.goodsCodeSConfig.demoText = this.defaultText
      this.goodsCodeSConfig.title = this.import_type_title1
      this.goodsCodeSConfig.tip = this.defaultTip
      this.goodsCodeSConfig.placeholder = this.defaultPlaceholder
    },
    // 设置类型为2的提示 
    setDefaultTipText2(){
      this.goodsCodeSConfig.demoTitle = this.import_type_title1
      this.goodsCodeSConfig.demoText = this.disType2Text
      this.goodsCodeSConfig.title = this.import_type_title1
      this.goodsCodeSConfig.tip = this.disType2Tip
      this.goodsCodeSConfig.placeholder = this.disType2Placeholder
    },
    //导入商品提交
    implodeSubmit() {
      // 提交表单数据
      let postData = { import_type:"",goods_code: [] };
      postData.import_type = this.import_type
      postData.goods_code = this.goodsCodeS.split("\n");
      // 数据验证
      if(postData.import_type ===""){
        this.$message.error("请选择导入的类型");
        return false
      }
      if(this.goodsCodeS===""){
        this.$message.error("请输入数据");
        return false
      }
      this.$axios
        .post("/a1/dis/importList/" + this.disId, postData)
        .then((res) => {
          this.$message.success("导入成功");
          this.getData();
          this.drawer = false;
        }).catch((err)=>{
          this.$alert(err.response.data.message, {
            confirmButtonText: '确定',
          });
        });
    },
    //显示导入的界面
    importShow() {
      this.goodsCodeS = "";
      this.drawer = true;
      // 如果是固定价，自定义折扣价的类型
      if (parseInt(this.disDataInfo.dis_type)===2) {
        this.setDefaultTipText2
      }
    },
    // 批量自定义删除界面
    selDelShow(){
      this.delGoodsCodeS = ""
      this.delDrawer = true;
    },
    // 批量自定义删除提交 
    batchDelGoodsSubmit(){
      // 提交表单数据
      let postData = { dis_id:0,goods_code: [] };
      postData.dis_id = this.disId
      postData.goods_code = this.delGoodsCodeS.split("\n");
      // 数据验证
      if(postData.dis_id ===0){
        this.$message.error("数据有误，请刷新界面");
        return false
      }
      if(this.delGoodsCodeS===""){
        this.$message.error("请输入数据");
        return false
      }
      this.$axios
        .delete("/a1/dis/batchDelGoods", {data:postData})
        .then((res) => {
          this.$message.success("删除成功");
          this.getData();
          this.delDrawer = false;
        }).catch((err)=>{
          this.$alert(err.response.data.message, {
            confirmButtonText: '确定',
          });
        });
    },
    //
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 直接关闭
    handleNowClose(done) {
      done();
    },
    //批量删除
    selDel() {
      if (this.multipleSelection.length > 0) {
        let ids = { ids: [] };
        let goodsPush = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          let theGoodsCode = this.multipleSelection[i].id;
          goodsPush.push(theGoodsCode);
        }
        ids.ids = goodsPush;
        this.$axios
          .delete("/a1/dis/delDisList", {
            data: ids,
          })
          .then((res) => {
            this.$message.success("删除成功");
            this.getData();
          });
      } else {
        this.$message.info("请选择商品");
      }
    },
    //获取活动详情
    getDisInfo() {
      this.$axios.get("/a1/dis/" + this.disId).then((res) => {
        // this.disName = res.data.data.name;
        this.disDataInfo = res.data.data
      });
    },
    getDisId() {
      var query = this.$route.query;
      this.disId = query.id;
    },
    getData() {
      this.$axios.get("/a1/dis/GetGoods/" + this.disId).then((res) => {
        this.tableData = res.data.data;
      });
    },
    //选中操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 编辑
    handleEdit(row){
      this.editDrawer = true
      for (const key in row) {
        this.form[key] = row[key]
      }
    },
    // 编辑提交
    onSubmitEdit(){
      var data =  {
        id:this.form.id,
        fixed_price:this.form.fixed_price?parseFloat(this.form.fixed_price):0.00
      }
      // 如果是固定价、自定义折扣价
      if (parseInt(this.disDataInfo.dis_type)===2) {
        data.dis_price = this.form.dis_price?parseFloat(this.form.dis_price):0.00
      }
      this.$axios.put("/a1/dis/editgoods/" + this.form.id, data).then((res) => {
        this.$message.success("修改成功");
        this.getData(); //获取到数据
        this.editDrawer = false;
      });

    },
  },
};
</script>

<style>
.disListDiv {
  border: 1px solid #889aa4;
  padding: 10px;
  margin: 0 auto;
  width: 98%;
  border-radius: 8px;
}
</style>
